export enum FeatureFlagEnum {

  CONFIG_VARIABLE_FREE_SIZE = 'frontend-configvar-free-size',
  DASHBOARD_REPORTS = 'frontend-dashboard-reports',
  RECENT_NAVIGATION_HOME_BOARD = 'frontend-new-home-board',
  SEARCH_INSTANCES_API = 'frontend-search-instances-api',
  CREATE_INSTANCE_INSTANCES_API = 'web-create-instance-api',
  GET_INSTANCE_INSTANCES_API= 'web-get-instance-api',
  GET_CONTAINER_INSTANCES_API = 'web-get-container-api',
  OBFUSCATE_INSTANCE_URL = 'web-obfuscate-instance-url',
  PROCESS_INSTANCES_API = 'web-process-instance-api',
  PARTIAL_FUNCTION_INSTANCES_API = 'web-partial-function-instance-api',
  REMOVE_GET_INSTANCE_PROFILE = 'web-remove-request-get-instance-profile',
  INBOX_ASSIGNMENT_INSTANCES_API = 'web-inbox-assignment-instance-api',
  SAVE_ROTATED_IMAGE = 'web-save-rotated-image',
}